<template>
  <div class="error-400">
    <div class="error-400__container">
      <div class="error-400__container__number">400</div>
      <div class="tw-font-medium tw-mt-12 tw-text-center tw-text-2xl sm:tw-text-4xl">
        {{ $t('400.title') }}
      </div>
      <div class="tw-text-gray-500 tw-mt-2 tw-text-center">
        {{ $t('400.description') }}
      </div>
      <div class="tw-flex tw-justify-center tw-font-medium tw-mt-12">
        <a :href="`${URLS.SSO}/?app_version=3&redirect=/&sign_out=1`">
          {{ $t('400.cta') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { URLS } from '@/config/urls.config'

export default {
  data: () => ({
    URLS,
  }),
}
</script>

<style lang="scss">
.error-400 {
  @include screen-flex;

  &__container {
    @include screen-container($padding: true, $h-center: true, $v-center: true);

    &__number {
      line-height: 1;
      font-size: 8rem;
      font-weight: 600;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 10rem;
      }
    }
  }
}
</style>
